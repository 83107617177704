import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeroKnowledgeBase from "../../components/pages/shared/hero/knowledge-base"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import CategoriesList from "../../../src/knowledgebase/components/knowledgebase-category-list"
import PostList from "../../../src/knowledgebase/components/knowledgebase-post-list"
import Grid from "../../../src/knowledgebase/components/knowledgebase-post-grid"
import { initSearch } from "../../knowledgebase/components/knowledgebase-utils"
import Breadcrumb from '../../components/controls/breadcrumbs';
// import DiscountBanner from "../../../src/knowledgebase/components/banner"

export default function IndexPage ({ data, props }) {
  const edges = data.allMarkdownRemark.edges
  const [searchResults, setSearchResults] = useState([])
  const isSearch = searchResults.length > 0
  console.log(searchResults)
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'База знаний', url: ''}
  ];

  return (
    <Layout page={"knowledgebase"} headerColor={"#319DED"}>
      <HeroKnowledgeBase name={"knowledgebase"} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"blue"}/>
      {/* <DiscountBanner/> */}
      <SEO title="База знаний" keywords={[`База знаний`]}
           url="https://paraplancrm.ru/knowledgebase/"
           description="Исследуйте функционал и возможности Параплан CRM, используя подробную базу знаний, которая содержит ответы на самые популярные вопросы о системе."
      />
      <Grid>
        <CategoriesList />
        <PostList posts={isSearch ? searchResults: edges} headline="Статьи" />
      </Grid>
      <ContactFormComponent color={"#F9F9FA"} />
    </Layout>
  )
}


export const knowledgebasePostQuery = graphql`
  query KnowledgebasePostsQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fileAbsolutePath: {regex: "/src/knowledgebase/"}
        frontmatter: { path: { ne: null } }
      }
    ) {
      edges {
        node {
          frontmatter {
            cover {
              childImageSharp {
                sm: fluid(maxWidth: 470) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                mob: fluid(maxWidth: 835) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            lead {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            excerpt
            path
            title
            category
            mark
          }
          timeToRead
        }
      }
    }
  }
`
