import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import styles from "./knowledgebase-category-list.module.scss"

const CategoryList = ({ currentCategory, currentPost }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [showCategory, setShowCategory] = useState(false)

  function toggleMenu() {
    const nextMenuOpen = !menuOpen
    setMenuOpen(nextMenuOpen)
  }

  return <div>
    <StaticQuery query={knowledgebaseCategoriesQuery} render={
      data => {
        const group = data.allMarkdownRemark.group;
        const categoriesList = group.map(category => {
          const [ name, slug ] = category.fieldValue.split("|")
          return <div
            className={`${styles.link} ${currentCategory === category.fieldValue? styles.currentCategory: ''}`}
            key={slug}
          >
            <a href={`/knowledgebase/category${slug}`}>
              {name}
              <span className={styles.count}>{category.totalCount}</span>
            </a>
          </div>
        })
        let postList = []
        const currentCategoryItem = group.find(group => group.fieldValue === currentCategory)
        if (currentCategoryItem) {
          const [ name, slug ] = currentCategoryItem.fieldValue.split('|')
          currentCategoryItem.title = name
          currentCategoryItem.slug = slug
        }

        if (currentCategory && currentPost) {
          postList = currentCategoryItem.edges.map(({ node }) => {
            return <div key={node.frontmatter.path}>
              <a
                className={`${styles.post} ${currentPost === node.frontmatter.path ? styles.currentPost: ''}`}
                href={`/knowledgebase/post${node.frontmatter.path}`}
              >
                {node.frontmatter.title}
              </a>
            </div>
          })

          postList = [
            (
              <div className={`${styles.link} ${styles.currentCategory}`} key={'back'}>
                <a href={`/knowledgebase/category${currentCategoryItem.slug}`}>
                  {currentCategoryItem.title}
                  <span className={styles.count}>{currentCategoryItem.totalCount}</span>
                </a>
              </div>
            ),
            ...postList
          ]
        }

        const isPostList = currentPost && !showCategory
        const animationClass = currentPost ? "animated slideInLeft faster":""


        return <div className={styles.category}>
          <div onClick={toggleMenu} className={`${styles.toggler} ${styles.title}`}> <span>Категории</span> </div>

          <div className={`${styles.list} ${menuOpen ? styles.open : ""}`}>

            {isPostList && <div className={`${styles.title} ${styles.titleBack}`} onClick={() => {setShowCategory(true)}}>
              <span>Назад к категориям</span>
            </div>}

            <div className={`${styles.title} ${!isPostList ? animationClass: styles.hidden}`}>
              <span>Категории</span>
            </div>

            {isPostList && <div>
              {postList}
            </div>}

            <div className={!isPostList ? animationClass: styles.hidden}>
              {categoriesList}
            </div>
          </div>
        </div>
      }
    } />
  </div>
}

export const knowledgebaseCategoriesQuery = graphql`
  query KnowledgebaseCategoriesQuery {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/src/knowledgebase/"}
        frontmatter: { path: {ne: null} }
      }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  }
`

export default CategoryList;
